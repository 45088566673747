import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import colors from '../config/color';
import RingHeader from '../components/ringHeader';
import PostCard from '../components/postCard';
import Pagination from '../components/pagination';
import SingleSidebarWidget from '../components/singleSidebarWidget';

class Homepage extends Component {
  render() {
    return (
      // <Layout showHeader={true} showHero={false}>
      <Layout>
        {/* TODO: SEO */}
        <SEO title="Here Hear 聽聽" />
        {/* <div className="search-bar-up">
          <BigSearchBar categories={data.allWordpressCategory.edges} />
          <Product title={''} edges={data.allWordpressPost.edges} />
          <div className="more-btn-content">
            <Link style={{ textAlign: 'center' }} to={'/posts/page/1'}>
              <div className="more">看更多</div>
            </Link>
          </div>
          <Category edges={data.allWordpressCategory.edges} />
        </div> */}
        <div className="content">
          <RingHeader />
          <h1>最新消息</h1>
          <div className="banner-mask"></div>
        </div>
        <div className="blog">
          <div>
            <div className="post-content">
              <PostCard
                title="大愛專訪 Here Hear: AI情緒智能感測APP！"
                text="「短短一句話就能知道你是不是深陷情緒風暴。」
                「短短十五秒的時間，就能透過AI分析大數據便能利用聲調來判讀情緒。」
                ー大愛電視"
                imageUri="/images/photo_02@3x.png"
              />
              <PostCard
                title="大愛專訪 Here Hear: AI情緒智能感測APP！"
                text="「未來我們會運用AI的技術，光是『聽』你的聲音就知道你的情緒是好是壞」 – Here Hear CEO 12月21日下午1點到4點半在二二八和平公園露天音樂台，精神健康基金會所舉辦的『2019臺灣精神健康日 三五成群 LOHAS Now！』園遊會，歡迎大家一起走出來，把心情說出來，走出情緒與疾病困擾的陰霾。 Here Hear APP，來自未來的情緒隨身管家，情緒困擾的大福音 心事從此不怕沒人聽。 大部份人在情緒不佳的時候，不知道如何發洩負面的能量。但你知道嗎？現在有一種新款的app不但可以聽你說，還可以讓你說。 現代的人面對家庭、課業、工作的壓力時，常常喘不過氣，卻又無處抒發自己"
                imageUri="/images/photo_02@3x.png"
              />
            </div>
            <div className="pagination-content">
              <Pagination totalPage={5} activePage={1} />
            </div>
          </div>
          <div className="category-content">
            <SingleSidebarWidget />
          </div>
        </div>

        <style jsx>{`
          .content {
            background-image: url('/images/happy-businessman-celebrating-victory-at-office-PV4ZPYY.png');
            background-repeat: no-repeat;
            background-position: center;
            width: 100%;
            height: 310px;
            text-align: center;
            position: relative;
            z-index: 0;
          }

          .banner-mask {
            width: 100%;
            height: 250px;
            background-color: ${colors['black']};
            opacity: 0.3;
            position: absolute;
            top: 60px;
            z-index: -1;
          }

          h1 {
            color: ${colors['white']};
            font-family: NotoSansCJKtc-Bold;
            font-size: 24px;
            line-height: 1.33;
            margin-top: 110px;
          }

          .post-content {
            margin-bottom: 40px;
            flex:7;
          }

          .pagination-content {
            margin-bottom: 40px;
          }

          .blog {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0 16px 40px 16px;
          }

          .category-content {
            flex: 3;
          }

          @media (min-width: 768px) {
            .content {
              background-image: url('/images/happy-businessman-riding-bicycle-to-work-in-CQDRP6Z.png');
              background-size: cover;
              height: 400px;
            }

            .banner-mask {
              height: 340px;
            }

            h1 {
              font-size: 48px;
              line-height: 60px;
              margin-top: 140 px;
            }

            .post-content {
              margin-bottom: 60px;
            }

            .pagination-content {
              margin-bottom: 60px;
            }

            .blog {
            flex-direction: row-reverse;
            margin-left: 60px;
            }

            .category-content {
              margin-top: 60px;
            }
        `}</style>
      </Layout>
    );
  }
}

export default Homepage;

export const pageQuery = graphql`
  query {
    allWordpressCategory {
      edges {
        node {
          wordpress_id
          name
          slug
        }
      }
    }
    hotCategory: allWordpressCategory(
      sort: { fields: count, order: DESC }
      limit: 8
    ) {
      edges {
        node {
          wordpress_id
          name
          slug
        }
      }
    }
    allWordpressPost(limit: 20) {
      edges {
        node {
          title
          slug
          categories {
            wordpress_id
            id
            name
            slug
          }
          tags {
            id
            name
            slug
          }
          jetpack_featured_media_url
        }
      }
    }
  }
`;
