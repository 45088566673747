import React from 'react';
import PropTypes from 'prop-types';
import ShoppingButton from './shoppingButton';
import colors from '../config/color';

const PostCard = props => {
  const { title, text, imageUri, imageAlt } = props;

  return (
    <div className="content">
      <img className="img-content" alt={imageAlt} src={imageUri} />
      <div className="text-content">
        <h3>{title}</h3>
        <p>{text}</p>
        <div className="shopping">
          <ShoppingButton text="閱讀全文" />
        </div>
      </div>

      <style jsx>{`
        .content {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 40px 16px 0 16px;
        }

        .text-content {
          display: flex;
          flex-direction: column;
        }

        h3 {
          color: ${colors['black-p']};
          font-family: NotoSansCJKtc-Medium;
          font-size: 18px;
          line-height: 1.44;
          margin: 20px 0;
        }

        p {
          color: ${colors['black-p']};
          font-family: NotoSansCJKtc-Regular;
          font-size: 14px;
          line-height: 1.43;
        }

        .shopping {
          margin-top: 20px;
        }

        .img-content {
          width: 100%;
          border-radius: 10px;
        }

        @media (min-width: 768px) {
          .content {
            margin: 60px 60px 0 60px;
          }

          .img-content {
            object-fit: cover;
          }

          h3 {
            font-size: 32px;
            margin: 20px 0;
          }

          p {
            font-size: 18px;
            line-height: 1.6;
          }

          .shopping {
            justify-content: flex-start;
          }

      `}</style>
    </div>
  );
};

PostCard.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  imageUri: PropTypes.string,
  imageAlt: PropTypes.string,
};

PostCard.defaultProps = {
  title: '',
  text: '',
  imageUri: '',
  imageAlt: '',
};

export default PostCard;
