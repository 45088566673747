import React from 'react';
import PropTypes from 'prop-types';
import colors from '../config/color';

const SingleSidebarWidget = props => {
  const { title, text1, text2, imageUri, imageAlt, imagePosition } = props;

  return (
    <div className="content">
      <h5>分類</h5>
      <ul>
        <li>
          <a href="#" className="category-item">
            自律神經不失調
          </a>
        </li>
        <li>
          <a href="#" className="category-item">
            身體
          </a>
        </li>
        <li>
          <a href="#" className="category-item">
            失眠這回事
          </a>
        </li>
        <li>
          <a href="#" className="category-item">
            心靈
          </a>
        </li>
        <li>
          <a href="#" className="category-item">
            憂鬱解鎖
          </a>
        </li>
      </ul>

      <style jsx>{`
        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          border: 1px ${colors['black-p']} solid;
          border-radius: 10px;
          padding: 20px;
          min-width: 200px;
        }

        h5 {
          color: ${colors['black-p']};
          font-family: NotoSansCJKtc-Medium;
          font-size: 14px;
          line-height: 1.44;
          padding: 8px 16px;
        }

        a {
          color: ${colors['black-p']};
          font-family: NotoSansCJKtc-Regular;
          font-size: 12px;
          line-height: 1.43;
          padding: 0 16px;
          text-decoration: none;
        }

        .shopping {
          padding-top: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .img-content {
          width: 100%;
          margin-top: -1px; 
        }

        @media (min-width: 768px) {
          h5 {
            font-size: 18px;
          }

          a {
            font-size: 16px;
            line-height: 1.6;

          }


      `}</style>
    </div>
  );
};

SingleSidebarWidget.propTypes = {
  title: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  imageUri: PropTypes.string,
  imageAlt: PropTypes.string,
  imagePosition: PropTypes.string,
};

SingleSidebarWidget.defaultProps = {
  title: '',
  text1: '',
  text2: '',
  imageUri: '',
  imageAlt: '',
  imagePosition: 'right',
};

export default SingleSidebarWidget;
